import React from 'react'
import Carosel from '../components/Home/Carosel'
// import BottomSlider from '../components/BottomSlider'
import styles from './eventManagement.module.css'
import topImage from '../img/background8.jpg'
import Budget from '../img/eventManagement/Rectangle1.png';
import Market from '../img/eventManagement/Rectangle2.png';
import Photo from '../img/eventManagement/Rectangle3.png';
import Emcee from '../img/eventManagement/Rectangle4.png';
import Psys from '../img/eventManagement/Rectangle5.png';
import Digital from '../img/eventManagement/Rectangle6.png';
import Branding from '../img/eventManagement/Rectangle7.png';
import Zoom from '../img/eventManagement/Rectangle8.png';
import Online from '../img/eventManagement/Rectangle9.png';
import Human from '../img/eventManagement/Rectangle10.png';
import Artist from '../img/eventManagement/Rectangle12.png';
import Designing from '../img/eventManagement/Rectangle13.png';


const EventManagement = () => {
    return (
        <>
            <div className={styles.outerBackground}>
                <div className={styles.TopSection}>
                   
                    <div className={styles.textSection}>
                        <h1>Event Management</h1>
                        <p>Event Solution aims to deliver the best solutions for all of your events, making event planning a stress-free. Our caring team of experts helps your vision become a reality and adds fascinating insights to your thoughts.</p>
                    </div>
                </div>

                <div className={styles.outerSection}>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Budget})` }} >
                        <div className={styles.divh5}>
                            <h5>Budget Creation & Management</h5>
                        </div>
                        <div className={styles.description}>
                            <p>Budgets, tracking and reporting . Site inspection, pre-event, on-site event management and logistics.</p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Market})` }} >
                        <div className={styles.divh5}>
                            <h5>Customized Marketing Strategy</h5>
                        </div>
                        <div className={styles.description}>
                            <p>We provide personalized marketing strategies based on the objectives and requirements of the client in order to make your event successful and unforgettable. </p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Photo})` }} >
                        <div className={styles.divh5}>
                            <h5>Photographs and Videographs</h5>
                        </div>
                        <div className={styles.description}>
                            <p>We provide professional event photography and videography services for the corporate, commercial, convention, exhibitions, conferences and many more events.</p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Emcee})` }} >
                        <div className={styles.divh5}>
                            <h5>Emcee</h5>
                        </div>
                        <div className={styles.description}>
                            <p>We provide you with your favorite Emcee who is master of ceremonies, who has hosted numerous events ranging from webinars to auctions, organizing concerts and festivals</p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Psys})` }} >
                        <div className={styles.divh5}>
                            <h5>PA System</h5>
                        </div>
                        <div className={styles.description}>
                            <p>We provide all Public Address systems, including microphones, amplifiers, loudspeakers, and related equipment, based on the needs of the customer. </p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Digital})` }} >
                        <div className={styles.divh5}>
                            <h5>Digital Marketing and Planning</h5>
                        </div>
                        <div className={styles.description}>
                            <p>We assist our customers in promoting their events through public relations, event planning, and social marketing tactics.We devise an effective marketing strategy for your events.</p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Branding})` }} >
                        <div className={styles.divh5}>
                            <h5>Branding</h5>
                        </div>
                        <div className={styles.description}>
                            <p>Theme and identity, across all marketing communications and experiential touchpoints </p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Zoom})` }} >
                        <div className={styles.divh5}>
                            <h5>Zoom Conference</h5>
                        </div>
                        <div className={styles.description}>
                            <p>We are a fantastic digital host who knows how to keep things moving for everyone. Your online conference/virtual event is a home run thanks to our professional and engaging presence!</p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Online})` }} >
                        <div className={styles.divh5}>
                            <h5>Online Registration Set Up and Management</h5>
                        </div>
                        <div className={styles.description}>
                            <p>Save the date, invite to register, RSVPs, motivational teasers, delegate registration, expo online booking app, website and mobile app</p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Human})` }} >
                        <div className={styles.divh5}>
                            <h5>Human Resource Management Service</h5>
                        </div>
                        <div className={styles.description}>
                            <p>Depending on the requirements of your event, we provide you HR services. Invest on safety, and adhere to proper HR practices Invest in technology that will help individuals operate more efficiently and quickly.</p>
                        </div>
                    </div>
                    <div className={styles.griditem} style={{ backgroundImage: `url(${Artist})` }} >
                        <div className={styles.divh5}>
                            <h5>Artist Management</h5>
                        </div>
                        <div className={styles.description}>
                            <p>If you are seeking for artists for any of your events, we provide a service where we will contact your selected artist and book them for your event.</p>
                        </div>
                    </div>

                    <div className={styles.griditem} style={{ backgroundImage: `url(${Designing})` }} >
                        <div className={styles.divh5}>
                            <h5>Designing and Printing</h5>
                        </div>
                        <div className={styles.description}>
                            <p>We take your ideas and bring it to life,focusing on its core values and individual element to create visually inspiring graphic design and printing.</p>
                        </div>
                    </div>

                </div>
            </div>
            <Carosel />
        </>
    )
}

export default EventManagement
