import React from "react";
import leaders from "../../leaders.json";
const card = () => {
  return (
    <div className="leaders">
      <div className="container" style={{ marginTop: "50px" }}>
        <h4 style={{ color: "white" }}>Creative Leaders</h4>
        <div className="cardleaders">
          {leaders.map((element, index) => (
            <div className="card-container" key={index}>
              <div className="cards">
                <div className="front">
                  <img src={element.img1} alt="sunil"></img>
                  <h2>{element.name}</h2>
                  <h3>{element.post}</h3>
                </div>
                <div className="back">
                  <p>
                    {!element.description
                      ? "..."
                      : element.description.slice(0, 480)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default card;
