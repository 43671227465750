import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Swiper.css";
import styles from "./swiper.module.css";
import "./Home/Upcomingevents.css";
//
import features from "../UpcomingEvents.json";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function App() {
  return (
    <div className="event-section container" style={{ paddingBottom: "40px" }}>
      <h4>Upcoming Events</h4>
      <div className="swiperContainer">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{ el: ".swiperPagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="MySwiper"
          id={styles.swipperouter}
        >
          {features.map((element) => {
            const { name, img, description, venue, RegisterLink } = element;
            console.log(img);
            return (
              <SwiperSlide key={features.img} id={styles.swiperSlide}>
                <div className="row outer-swiper">
                  <div className="col-lg-8 col-md-6 col-sm-6">
                    <img src={!img ? "" : img} alt="eventImag"></img>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 EventsDescription">
                    <h6>Venue : {venue}</h6>
                    <h2>{name}</h2>
                    <p>{!description ? "..." : description.slice(0, 200)}</p>
                    <h3>Date : {element.date}</h3>
                    <div className="button">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={RegisterLink}
                      >
                        <button>Book Now!</button>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className={styles.swiperOuter}>
                                    <div className={styles.swiperimage}>
                                        <img id={styles.bottomimage} src={!img ? "" : img} alt="eventImag" ></img>
                                    </div>
                                    <div className={`${styles.description} ${styles.EventsDescription}`}>
                                        <h6>Venue : {venue}</h6>
                                        <h2>{name}</h2>
                                        <p>{!description ? "..." : description.slice(0, 120)}</p>
                                        <h3>Date : {element.date}</h3>
                                        <div className='button'>
                                            <button>Book Now!</button>
                                        </div>
                                    </div>
                                </div> */}
              </SwiperSlide>
            );
          })}
          <div className="slider-controler events-swiper">
            <div className="swiper-button-prev slider-arrow event-slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow event-slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className="swiperPagination"></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
}
