import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import imageBig from '../../img/background3.jpg'
import imageBig from '../../img/GroupPhoto9.jpg'

// import imageSmall from '../../img/background1.jpg'
import imageSmall from '../../img/GroupPhoto11.jpeg'

const AboutHome = () => {
    const [size, setSize] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const isMobileView = window.innerWidth < 960;
            setSize(isMobileView)
            // console.log(size)
            // console.log(window.innerWidth)
            if (!isMobileView) {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("show");
                        }
                    });
                });

                const hiddenElsLeft = document.querySelectorAll('.hiddenLeft');
                const hiddenElsRight = document.querySelectorAll('.hiddenRight');

                hiddenElsLeft.forEach((el) => observer.observe(el));
                hiddenElsRight.forEach((el) => observer.observe(el));

                return () => {
                    hiddenElsLeft.forEach((el) => observer.unobserve(el));
                    hiddenElsRight.forEach((el) => observer.unobserve(el));
                };
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);






    return (
        <div className='about-outer-section'>
            <div className='about-image-section hiddenLeft'>
                <img src={imageBig} alt="imageBig"></img>
                <div className='about-image-small'>
                    <img src={imageSmall} alt="imageBig"></img>
                </div>
            </div>
            <div className={size ? 'outer-about-text-section ' : 'outer-about-text-section hiddenRight'}>
                <div className='about-text-section'>
                    <h6>About Us</h6>
                    <h2>Get to know Us..</h2>
                    <div className='text-section'>
                        <p><span>Event Solution Pvt. Ltd.</span>, founded in 2014, is a constructive and highly dedicated organization founded with the goal of managing and arranging various events in various fields. The name says it all: Event Solution is a firm that aims to deliver the best solutions for all of your events, making event planning a stress-free.</p>
                    </div>
                    <div>
                        <Link to="/contact" className="btn text-button" >Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHome
