import React from "react";
import CLIENTS from "../clients.json";
import styles from "./clients.module.css";
// import styles from './'

const Clients = () => {
  return (
    <div className={styles.outerSection}>
      <div className={styles.TopSection}>
        <div className={styles.textSection}>
          <h2>Our Valuable Clients</h2>
          <p>
            Event Solution appreciates each and every one of our clients who we
            have worked for day and night. They are the reason for our growth
            and progress.{" "}
          </p>
        </div>
      </div>

      <div className={styles.clientOuterSection}>
        <div className={styles.clientsSlide}>
          <a href={CLIENTS[0].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[0].img} alt={CLIENTS[0].name} />
          </a>
          <a href={CLIENTS[1].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[1].img} alt={CLIENTS[1].name} />
          </a>
          <a href={CLIENTS[2].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[2].img} alt={CLIENTS[2].name} />
          </a>
          <a href={CLIENTS[3].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[3].img} alt={CLIENTS[3].name} />
          </a>
          <a href={CLIENTS[4].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[4].img} alt={CLIENTS[4].name} />
          </a>
          <a href={CLIENTS[5].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[5].img} alt={CLIENTS[5].name} />
          </a>
          <a href={CLIENTS[6].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[6].img} alt={CLIENTS[6].name} />
          </a>
          <a href={CLIENTS[7].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[7].img} alt={CLIENTS[7].name} />
          </a>
          <a href={CLIENTS[8].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[8].img} alt={CLIENTS[8].name} />
          </a>
          <a href={CLIENTS[9].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[9].img} alt={CLIENTS[9].name} />
          </a>
          <a href={CLIENTS[10].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[10].img} alt={CLIENTS[10].name} />
          </a>
          <a href={CLIENTS[11].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[11].img} alt={CLIENTS[11].name} />
          </a>
          <a href={CLIENTS[12].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[12].img} alt={CLIENTS[12].name} />
          </a>
          <a href={CLIENTS[13].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[13].img} alt={CLIENTS[13].name} />
          </a>
          <a href={CLIENTS[14].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[14].img} alt={CLIENTS[14].name} />
          </a>
          <a href={CLIENTS[15].url} target="_blank" rel="noopener noreferrer">
            <img src={CLIENTS[15].img} alt={CLIENTS[15].name} />
          </a>
          <a href={CLIENTS[16].url} target="_blank" rel="noopener noreferrer">
            <img
              className={styles.webtunedLogo}
              src={CLIENTS[16].img}
              alt={CLIENTS[16].name}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Clients;
