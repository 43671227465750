import React from "react";
import Swiper from "../components/Swiper";
// import { Parallax } from 'react-parallax';
import Services from "../components/Home/Services";
import Clients from "../components/Home/Clients";
import Cards from "../components/Home/Cards";
import Events from "../components/Home/UpcomingEvents";
import AboutHome from "../components/Home/AboutHome";

import { useRef } from "react";
import Carosel from "../components/Home/Carosel";

const Home = () => {
  const downref = useRef();
  const handleScrollToDown = () => {
    downref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      {/* <Landing /> */}
      <Events downref={downref} handleScrollToDown={handleScrollToDown} />
      <Services />
      <AboutHome />
      <Clients />
      <Cards />
      <div ref={downref}>
        <Swiper />
      </div>
      <div ref={downref}>{/* <Carosel /> */}</div>
    </div>
  );
};

export default Home;
