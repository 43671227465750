import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./carosel.module.css";
import sprite from "./sprite.svg";
import features from "../../UpcomingEvents.json";

// const images = [
//     "https://res.cloudinary.com/ifeomaimoh/image/upload/v1652345767/demo_image2.jpg",
//     "https://res.cloudinary.com/ifeomaimoh/image/upload/v1652366604/demo_image5.jpg",
//     "https://res.cloudinary.com/ifeomaimoh/image/upload/v1652345874/demo_image1.jpg",
// ];

const Carosel = () => {
  const [color, setColor] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/about") {
      setColor(true);
    }
  }, [location.pathname]);

  const [autoplay, setAutoplay] = useState(true); // eslint-disable-line no-unused-vars

  const rotateAnimationHandler = (props, state) => {
    const transitionTime = props.transitionTime + "ms";
    const transitionTimingFunction = "ease-in-out";
    let slideStyle = {
      display: "block",
      minHeight: "100%",
      transitionTimingFunction: transitionTimingFunction,
      msTransitionTimingFunction: transitionTimingFunction,
      MozTransitionTimingFunction: transitionTimingFunction,
      WebkitTransitionTimingFunction: transitionTimingFunction,
      OTransitionTimingFunction: transitionTimingFunction,
      position:
        state.previousItem === state.selectedItem ? "relative" : "absolute",
      inset: "0 0 0 0",
      zIndex: state.previousItem === state.selectedItem ? "1" : "-2",
      opacity: state.previousItem === state.selectedItem ? "1" : "0",
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    };
    return {
      slideStyle,
      selectedStyle: {
        ...slideStyle,
        opacity: 1,
        position: "relative",
        zIndex: 2,
        filter: "blur(0)",
        transform: "translateX(0)", // Add slide animation
      },
      prevStyle: {
        ...slideStyle,
        transformOrigin: "0 100%",
        opacity: "0",
        filter:
          state.previousItem === state.selectedItem ? "blur(0px)" : "blur(5px)",
        transform: `translateX(${
          state.previousItem > state.selectedItem ? "-100%" : "100%"
        })`, // Add slide animation
      },
    };
  };
  // const handleToggleAutoplay = () => {
  //     setAutoplay(!autoplay);
  // };
  return (
    <div style={{ marginBottom: "4rem" }}>
      <h4 className={color ? "RedColorH4" : ""} style={{ marginTop: "4rem" }}>
        Upcoming Events
      </h4>
      <div className={styles.box}>
        <Carousel
          useKeyboardArrows={true}
          showIndicators
          autoPlay={autoplay} // Enable autoplay
          infiniteLoop={true}
          swipeable={false}
          showThumbs={false}
          onSlideChange={(index) => {
            console.log("Slide changed to index:", index);
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              hasNext && (
                <button
                  className={`${styles.nav_btn} ${styles.nav_btn_right}`}
                  onClick={clickHandler}
                >
                  <svg>
                    <use xlinkHref={sprite + "#right"}></use>
                  </svg>
                </button>
              )
            );
          }}
          renderArrowPrev={(clickHandler, hasNext) => {
            return (
              hasNext && (
                <button
                  onClick={clickHandler}
                  className={`${styles.nav_btn} ${styles.nav_btn_left}`}
                >
                  <svg>
                    <use xlinkHref={sprite + "#left"}></use>
                  </svg>
                </button>
              )
            );
          }}
          renderIndicator={(clickHandler, isSelected, index) => {
            const activeClassName = isSelected ? styles.active : "";
            return (
              <li
                onClick={clickHandler}
                className={`ind ${activeClassName}`}
                key={index}
                role="button"
              />
            );
          }}
          statusFormatter={(currentItem, total) => {
            return (
              <p>
                image {currentItem} of {total}
              </p>
            );
          }}
          transitionTime={410}
          animationHandler={rotateAnimationHandler}
        >
          {/* {images.map((URL, index) => (
                    <div className={styles.slide}>
                        <img alt="sample_file" src={URL} key={index} />
                    </div>
                ))} */}
          {features.map((element, index) => {
            const { name, img, description, venue, RegisterLink } = element;
            return (
              <div className="row outer-swiper" key={index}>
                <div className="col-lg-8 col-md-6 col-sm-6">
                  <img src={!img ? "" : img} alt="eventImag"></img>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 EventsDescription">
                  <h6 style={{ textAlign: "left" }}>Venue : {venue}</h6>
                  <h2 style={{ textAlign: "left" }}>{name}</h2>
                  <p style={{ textAlign: "justify" }}>
                    {!description ? "..." : description.slice(0, 258)}
                  </p>
                  <h3 style={{ textAlign: "left" }}>Date : {element.date}</h3>
                  <h3 style={{ textAlign: "left" }}>{element.date1}</h3>
                  <div style={{ textAlign: "left" }}>
                    {/* Book Now */}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={RegisterLink}
                    >
                      <button className="book-button">Book Now!</button>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
        {/* <button onClick={handleToggleAutoplay}>
                {autoplay ? "Disable Autoplay" : "Enable Autoplay"}
            </button> */}
      </div>
    </div>
  );
};

export default Carosel;
