// import Landing from './components/Home/Landing';
import Website from './pages/Website';


function App() {

  return (
    <>
      <Website />
      {/* <Landing /> */}
    </>
  );
}

export default App;
