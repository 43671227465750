import React, { useState, useCallback } from 'react'
import styles from './Hangers.module.css'
import rentals1 from '../../img/Rentals/stall/2M X 2M STALL.jpg'
import rentals2 from '../../img/Rentals/stall/2M X 2M.jpg'
import rentals3 from '../../img/Rentals/stall/3M X 2M.jpg'
import rentals4 from '../../img/Rentals/stall/3M X 3M.jpg'
import rentals5 from '../../img/Rentals/stall/MAIMA 3M X 2M.jpg'
import rentals6 from '../../img/Rentals/stall/MAXIMA 3M X 3M.jpg'
import rentals7 from '../../img/Rentals/stall/PHOTO BOOTH 1.jpg'
import rentals8 from '../../img/Rentals/stall/PHOTOBOOTH 2.jpg'
import rentals9 from '../../img/Rentals/stall/PHOTOBOOTH ZIGZAG.jpg'
import rentals10 from '../../img/Rentals/stall/STALL 6M X 3M.jpg'
import rentals11 from '../../img/Rentals/stall/12M X 40 M Sideways.jpg'



const Stalls = () => {
    const [model, setModel] = useState(false);
    const [tempSrc, setTempSrc] = useState(null);

    const getImg = useCallback((img) => {
        setTempSrc(img);
        setModel(true);
    }, []);
    const handleModelClose = () => {
        setModel(false)
    }
    return (
        <div>
            <div className={model ? `${styles.model} ${styles.open}` : styles.model}>
                <div className={styles.modelimage} >
                    {tempSrc && (
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                            <i className="fa-solid fa-xmark fa-2xl" style={{ color: "#ffffff" }} onClick={handleModelClose}></i>
                            <img src={tempSrc} alt="large" style={{ width: '65%' }} />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.hangers}>

                <div className={styles.Card}>
                    <div className={styles.imagesection} onClick={() => getImg(rentals1)}>
                        <img src={rentals1} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Small
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>Size : 2M X 2M</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals2)}>
                        <img src={rentals2} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Small
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>Size : 2M X 2M</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals3)}>
                        <img src={rentals3} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Medium
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>Size : 3M X 2M</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals4)}>
                        <img src={rentals4} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Medium
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>Size : 3M X 3M</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals5)}>
                        <img src={rentals5} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Medium
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>MAXIMA 3M X 2M</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals6)}>
                        <img src={rentals6} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Big
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>MAXIMA 3M X 3M</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals7)}>
                        <img src={rentals7} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Photo Booth
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>PHOTOBOOTH</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals8)}>
                        <img src={rentals8} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Photo Booth
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>PHOTOBOOTH</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals9)}>
                        <img src={rentals9} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Photo Booth
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>PHOTOBOOTH ZIGZAG</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals10)}>
                        <img src={rentals10} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Big
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>6M X 3M</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals11)}>
                        <img src={rentals11} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Big
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>12M X 40 M Sideways</div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default Stalls
