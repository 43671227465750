import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Navbar from '../components/Navbar';
import Projects from './Projects';
import Gallery from './Gallery';
import Contact from './Contact';
import Footer from '../components/Footer';
import Rentals from './Rentals';
import EventManagement from './EventManagement';
import ScrollToTop from '../components/ScrollToTop';
import Clients from './Clients';
import { NameProvider } from '../context/NameContext';


const Website = () => {
    return (
        <div>
            <BrowserRouter>
                <ScrollToTop />
                <Navbar />
                <div >
                    <NameProvider>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/projects" element={<Projects />} />
                            <Route path="/gallery" element={<Gallery />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/rentals" element={<Rentals />} />
                            <Route path="/eventManagement" element={<EventManagement />} />
                            <Route path="/clients" element={<Clients />} />
                        </Routes>
                    </NameProvider>
                </div>
                <Footer />
            </BrowserRouter>
        </div>
    )
}

export default Website





