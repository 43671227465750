import React from 'react'
import CLIENTS from '../../clients.json'
import DoubleLines from './DoubleLines';
import MarqueeSection from './MarqueeSection';
// import Marquee from "react-fast-marquee";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';



const Clients = () => {
    const [small, setSmall] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 992) {
                setSmall(true);
            } else {
                setSmall(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check on component mount
        if (window.innerWidth < 992) {
            setSmall(true);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div className='clients'>
            <div id="container">
                <div className="photobanner">
                    <h1 style={{ marginBottom: "15px" }}>Our clients</h1>
                    {/* <DoubleLines /> */}
                    {/* <MarqueeSection clients={CLIENTS} /> */}

                    {/* <Marquee speed={100}>
                        {CLIENTS.map((element) => {
                            return (
                                <a href={element.url} target="_blank"><img key={element.name} src={element.img} alt={element.name} /></a>
                            )
                        })}
                    </Marquee> */}

                    {small ? <MarqueeSection clients={CLIENTS} /> : <DoubleLines />}
                    <div>
                        <Link to="/clients" className="btn view-button" >View More</Link>
                    </div>
                    {/* <Marquee speed={100}>
                        <div className={styles.clientOuterSection}>
                            <div className={styles.clientsSlide}>
                                <img src={CLIENTS[0].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[1].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[2].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[3].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[4].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[5].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[6].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[7].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[8].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[9].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[10].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[11].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[12].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[13].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[14].img} alt={CLIENTS[0].name} />
                                <img src={CLIENTS[15].img} alt={CLIENTS[0].name} />
                            </div>
                        </div>
                    </Marquee> */}
                </div>
            </div>
        </div>
    );
}
export default Clients;
