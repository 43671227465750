import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
// import Swiper from '../components/Home/Swiper'
// import contactbg from '../img/contactUS4.jpg'
import handshake from "../img/contactHandshake.jpg";
// import Carosel from '../components/Home/Carosel'

// import Events from '../components/Home/Events';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2lrl3mq",
        "template_uav456c",
        form.current,
        "rbEa7cK7GW4bF94N6"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message has been sent!");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="contacts container">
      <div className="contactBanner ">
        <div className="contactUs-Backdrop">
          <img src={handshake} alt="contactbg"></img>
        </div>
        <h1>Contact Us for a complete solution to all events</h1>
      </div>
      <div className="row my-5">
        <div className="col-lg-4 col-md-4 locationtxt">
          <p id="title">Event Solution Pvt. Ltd</p>
          <p>Jwagal, Lalitpur, Nepal</p>
          <p>Tel:+977-01-5260535/+977-01-5260103</p>
          <p>
            <a
              href="https://wa.me/9779841503622"
              style={{ textDecoration: "none", color: "white" }}
            >
              <i
                className="fa-brands fa-whatsapp fa-xl"
                style={{ color: "white" }}
              ></i>{" "}
              +977-9841503622
            </a>
          </p>
          <p className="gmail">
            <a
              style={{ color: "white", textDecoration: "none" }}
              href="mailto:info@eventsolutionnepal.com.np"
            >
              info@eventsolutionnepal.com.np
            </a>
          </p>
        </div>
        <div className="col-lg-7 col-md-7 contactForm">
          <p>Please fill out this form and we will get back to you shortly.</p>
          <form ref={form} onSubmit={sendEmail}>
            <div className="Contactgrid">
              <div className="Contactgrid-item">
                <div className="form-group">
                  <label>Enter Your Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstname"
                    id="FirstName"
                    placeholder="Full Name"
                    required
                  />
                </div>
              </div>
              <div className="Contactgrid-item">
                <div className="form-group">
                  <label>Enter Your Last Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastname"
                    id="LastName"
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
              <div className="Contactgrid-item">
                <div className="form-group">
                  <label>Enter Your Phone *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="user_Phone"
                    id="Phone"
                    placeholder="Phone"
                    pattern="[0-9]+"
                    required
                  />
                </div>
              </div>
              <div className="Contactgrid-item">
                <div className="form-group">
                  <label>Enter Your Email *</label>
                  <input
                    type="email"
                    className="form-control"
                    name="user_email"
                    id="Email"
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div className="Contactgrid-item span-2">
                <div className="form-group">
                  <label>Type Your Message Here *</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    name="user_message"
                    rows="3"
                    placeholder="Message"
                  ></textarea>
                </div>
              </div>
              <div className="Contactgrid-item span-2">
                <button type="submit" className="btn contactbtn">
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row" style={{ marginTop: "70px" }}>
        <div className="col-lg-6 col-md-6 contactText">
          <p>You bring the guest we will take care of the rest</p>
        </div>
        <div className="col-lg-5 maps">
          {/* <iframe title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.063337511994!2d85.32204707471531!3d27.684437226502894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb184cb58aa15b%3A0xae246264d3ab0440!2sEVENT%20SOLUTION%20PVT.%20LTD!5e0!3m2!1sen!2snp!4v1686288410544!5m2!1sen!2snp" height="300" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" alt="maps"></iframe> */}
          <iframe
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.063337511986!2d85.32204707471531!3d27.684437226502894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb184cb58aa15b%3A0xae246264d3ab0440!2sEVENT%20SOLUTION%20PVT.%20LTD!5e0!3m2!1sen!2snp!4v1686291350965!5m2!1sen!2snp"
            width="400"
            height="300"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      {/* <Carosel />s */}
    </div>
  );
};

export default Contact;
