import React from "react";
import webtuned from "../img/webtuned.png";

const Footer = () => {
  const currentdate = new Date();
  const date = currentdate.getFullYear();
  return (
    <div className="Footer" style={{ marginBottom: "0px" }}>
      <div className="row container-fluid">
        <div className="col-lg-4 ">
          <img src="images/logo1.png" alt="logo"></img>
        </div>
        <div className="col-lg-4 sub">
          <p className="subscribe">Subscribe and Follow</p>
          <a href="https://www.facebook.com/eventsolutionnepal">
            <i className="fa-brands fa-facebook fa-xl"></i>
          </a>
          <a href="https://www.linkedin.com/in/event-solution-0b437b1a1/">
            <i className="fa-brands fa-linkedin fa-xl"></i>
          </a>
          <a href="https://www.instagram.com/eventsolutionnepal/">
            <i className="fa-brands fa-square-instagram fa-xl"></i>
          </a>
          <a href="https://www.tiktok.com/@eventsolutionnp">
            <i className="fa-brands fa-tiktok fa-xl"></i>
          </a>
          <a href="https://wa.me/9779841503622">
            <i className="fa-brands fa-whatsapp fa-xl"></i>
          </a>
          <a href="https://invite.viber.com/?g2=AQAxZOgB%2B7IeSktn9WPCFT5HGWrBuv%2FG4NoMztJCNGbEFghBBsF4feQQnwPWpAe3 ">
            <i class="fa-brands fa-viber fa-xl"></i>
          </a>
        </div>
        <div className="col-lg-4 ">
          <p id="location1">
            <span>
              <i
                className="fa-solid fa-location-dot"
                style={{ display: "inline" }}
              ></i>{" "}
              Jwagal, Lalitpur, Nepal
            </span>
            <span>
              <a
                style={{
                  textDecoration: "none",
                  color: "white",
                  /* Normal styles */
                  ":hover": {
                    textDecoration: "none",
                    color: "white",
                  },
                }}
                href="tel:+977015260535"
              >
                <i
                  className="fa-solid fa-phone"
                  style={{ display: "inline" }}
                ></i>{" "}
                +977-01-5260535/+977-01-5260103
              </a>
            </span>
            {/* <span className='next-line'>+977-01-5260103</span> */}
            <span>
              <a
                style={{
                  textDecoration: "none",
                  color: "white",
                  /* Normal styles */
                  ":hover": {
                    textDecoration: "none",
                    color: "white",
                  },
                }}
                href="mailto:info@eventsolutionnepal.com.np"
              >
                <i
                  className="fa-solid fa-envelope "
                  style={{ display: "inline" }}
                ></i>{" "}
                info@eventsolutionnepal.com.np
              </a>
            </span>
          </p>
        </div>
      </div>
      <hr></hr>
      <div
        className="copyright justify-content-center align-items-center"
        style={{ paddingBottom: "35px" }}
      >
        <span style={{ margin: "0px" }}>
          Copyright {date} @ Event Solution Pvt. Ltd.{" "}
          <span className="next-line"> All Rights Reserved.</span>{" "}
        </span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://webtunedstudio.com.np/"
        >
          <img id="footer-company-image" src={webtuned} alt="webtuned" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
