import React from "react";
import Carosel from "../components/Home/Carosel";
import styles from "./projects.module.css";
import Project from "../Projects.json";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { NameContext } from "../context/NameContext";

const Projects = () => {
  const { setName } = useContext(NameContext);
  Project.sort((a, b) => {
    return parseInt(b.year) - parseInt(a.year);
  });
  const handleClick = (name) => {
    setName(name);
  };

  return (
    <div>
      <div className={styles.TopSection}>
        <div className={styles.textSection}>
          <h1>Projects</h1>
          <p>
            We have hundreds of clients ranging from major corporations to
            global brands, and we have successfully designed, coordinated, and
            executed large-scale events.
          </p>
        </div>
      </div>
      <div className={styles.workflowbody}>
        {/* <h2>Our Projects</h2> */}
        {Project.map((element, index) => {
          const { tagname } = element;
          return (
            <div className={styles.skewed}>
              <div className={styles.text}>
                <small
                  style={{ textTransform: "uppercase", letterSpacing: "1px" }}
                >
                  Venue : {element.venue}
                </small>
                <h1>{element.name}</h1>
                <p style={{ margin: "5px 0px" }}>Date : {element.date}</p>
                <p style={{ margin: "5px 0px" }}>
                  {!element.description
                    ? "..."
                    : element.description.slice(0, 200)}
                </p>
                <Link
                  to="/gallery"
                  onClick={() => handleClick(tagname)}
                  className={styles.textButton}
                >
                  More Photos
                </Link>{" "}
              </div>
              <div className={styles.image}>
                <div>
                  <img src={element.img} alt="good"></img>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Carosel />
    </div>
  );
};

export default Projects;
