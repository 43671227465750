import React, { useState, useEffect, useRef } from 'react'
import { useLocation, NavLink, Link } from 'react-router-dom';
import logo from '../../src/img/logo1.png'


const Navbar = () => {
    //change navbar color on scroll
    const toggleButtonRef = useRef(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
    // const [hide, setHide] = useState(true);

    const [color, setColor] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
        // setIsCollapsed(false);
    };
    // const [color, setColor] = useState(true);
    const changecolor = () => {
        if (window.scrollY < 110) {
            setColor(false)
        } else {
            setColor(true)
        }
    }
    const location = useLocation();



    useEffect(() => {
        if (location.pathname === "/" || location.pathname === '/about') {
            window.addEventListener('scroll', changecolor);
        } else {
            setColor(true)
        }
        let locationString = location.pathname.slice(1);
        if (!locationString) {
            locationString = "home"
        }
        const str2 = locationString.charAt(0).toUpperCase() + locationString.slice(1);
        // const str2 = locationString.toUpperCase();
        let titleString = "Event Solution - " + str2;
        document.title = titleString
        return () => {
            window.removeEventListener('scroll', changecolor);
        };
    }, [location.pathname]);


    const handleAboutClick = () => {
        // if (toggleButtonRef.current) {
        //     toggleButtonRef.current.click();
        // }
        // setIsCollapsed(!isCollapsed);
        if (window.innerWidth < 992 && toggleButtonRef.current) {
            toggleButtonRef.current.click();
        }
    };
    const handleAboutClickDrop = () => {
        // if (toggleButtonRef.current) {
        //     toggleButtonRef.current.click();
        // }
        // setIsCollapsed(!isCollapsed);
        if (window.innerWidth < 992 && toggleButtonRef.current) {
            setIsCollapsed(false);
            toggleButtonRef.current.click();
        }

    };

    const getNavbarTogglerIcon = () => {
        return isCollapsed ? (
            <i className="fas fa-xmark" style={{ color: "#fff", fontSize: "25px" }}></i>
        ) : (
            <i className="fas fa-bars" style={{ color: "#fff", fontSize: "25px" }}></i>
        );
    };
    const ServicesHover = (e) => {
        if (window.innerWidth > 992) {
            e.target.click()
        }
    }
    return (
        <div className='navbar-container' >
            <nav className={color ? 'navbar navbar-expand-lg fixed-top header-bg ' : 'navbar navbar-expand-lg fixed-top header-dark '}>
                <div className="container-fluid">
                    <NavLink className="navbar-brand" style={{ backgroundColor: 'transparent' }} id="logoBrand" to="/" ><img src={logo} alt="logo"></img></NavLink>
                    <button ref={toggleButtonRef} className="navbar-toggler" onClick={toggleCollapse} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                        <span className="navbar-toggler-icon" >
                            {getNavbarTogglerIcon()}
                        </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                        <ul className={isCollapsed ? "navbar-nav ms-auto mb-2 mb-lg-0 header-bg" : "navbar-nav ms-auto mb-2 mb-lg-0 "} >
                            <li className="nav-item mx-4">
                                <NavLink className="nav-element nav-link" aria-current="page" to="/" onClick={handleAboutClick}>Home</NavLink>
                            </li>
                            <li className="nav-item mx-4 dropdown" onMouseLeave={ServicesHover}>
                                <Link
                                    className="nav-link"
                                    style={{ color: 'white', borderBottom: 'none', textDecoration: "none" }}
                                    to="/"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onMouseOver={(e) => e.target.click()}
                                >
                                    Services
                                </Link>
                                <ul className="dropdown-menu dropdown-hover" style={{ backgroundColor: 'black' }}>
                                    <li ><NavLink className="nav-drop nav-element nav-item dropdown-item my-2" to="/rentals" onClick={() => { handleAboutClick(); }}>Rentals</NavLink></li>
                                    <li ><NavLink className="nav-drop nav-element nav-item dropdown-item my-2" to="/eventManagement" onClick={handleAboutClickDrop}>Event Management</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item mx-4">
                                <NavLink className="nav-element nav-link " to="/about" onClick={handleAboutClick}>About</NavLink>
                            </li>
                            <li className="nav-item mx-4">
                                <NavLink className="nav-element nav-link " to="/projects" onClick={handleAboutClick}>Projects</NavLink>
                            </li>
                            <li className="nav-item mx-4">
                                <NavLink className="nav-element nav-link " to="/gallery" onClick={handleAboutClick}>Gallery</NavLink>
                            </li>
                            {/* <li className="nav-item mx-4">
                                <NavLink className="nav-element nav-link " to="/clients">Clients</NavLink>
                            </li> */}
                            <li className="nav-item mx-4">
                                <NavLink className="nav-element nav-link " to="/contact" onClick={handleAboutClick}>Contact Us</NavLink>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
