import React from 'react'
import styles from './DoubleLines.module.css'
import CLIENTS from '../../clients.json'

const DoubleLines = () => {
    return (
        <div className={styles.outerSection}>
            <img src={CLIENTS[0].img} alt={CLIENTS[0].name} />
            <img src={CLIENTS[1].img} alt={CLIENTS[0].name} />
            <img src={CLIENTS[2].img} alt={CLIENTS[0].name} />
            <img src={CLIENTS[3].img} alt={CLIENTS[0].name} />
            <img src={CLIENTS[4].img} alt={CLIENTS[0].name} />
            <img src={CLIENTS[5].img} alt={CLIENTS[0].name} />
            <img src={CLIENTS[6].img} alt={CLIENTS[0].name} />
            <img src={CLIENTS[7].img} alt={CLIENTS[0].name} />
            <img src={CLIENTS[8].img} alt={CLIENTS[0].name} />
            <img src={CLIENTS[9].img} alt={CLIENTS[0].name} />
        </div>
    )
}

export default DoubleLines
