import React from "react";
import TopSection from "../components/About/TopSection";
import DetailsSection from "../components/About/DetailsSection";
import WorkFlow from "../components/About/WorkFlow";
import Carosel from "../components/Home/Carosel";
// import Carosel from '../caroselTrial/Carosel'

const About = () => {
  return (
    <>
      <TopSection />
      <DetailsSection />
      <WorkFlow />
      <Carosel />
    </>
  );
};

export default About;
