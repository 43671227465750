import React from 'react';
import Marquee from "react-fast-marquee";
import CLIENTS from '../../clients.json';

const MarqueeSection = () => {
    return (
        <div className='marquee-section'>
            <Marquee speed={100}>
                {CLIENTS.map((element) => (
                    <a href={element.url} target="_blank" rel="noopener noreferrer" key={element.name}>
                        <img src={element.img} alt={element.name} />
                    </a>
                ))}
            </Marquee>
        </div>
    );
};

export default MarqueeSection;