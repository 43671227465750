import React from "react";
import styles from "./TopSection.module.css";
// import topImage from '../../img/AboutPage1.jpg'
import topImage from "../../img/GroupPhoto1.jpg";

const TopSection = () => {
  return (
    <div>
      <div className={styles.TopSection}>
        <div className={styles.textSection}>
          <h1>About Us</h1>
          <p>
            Event Solution Pvt.Ltd. established in 2014 is a constructive and
            highly dedicated company initiated with an objective of managing and
            organizing different events in respect to different fields.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
