import React, { useState, useCallback } from 'react'
import rentals1 from '../../img/Rentals/Stage/CONCERT STAGE.jpg'
import rentals2 from '../../img/Rentals/Stage/STAGE WITH CHAIRS.jpg'
import rentals3 from '../../img/Rentals/Stage/STAGE WITH DECORATION.jpg'
import rentals4 from '../../img/Rentals/Stage/STAGE WITH LED SCREEN.jpg'
import rentals5 from '../../img/Rentals/Stage/STAGE.jpg'
import rentals6 from '../../img/Rentals/Stage/TRUSS STAGE.jpg'
// import rentals7 from '../../img/Rentals/Stage/CONCERT STAGE.jpg'
import rentals8 from '../../img/Rentals/Lights/OCTAGON GATE.jpg'
import rentals9 from '../../img/Rentals/Lights/ARC WITH LIGHTS.jpg'
import rentals10 from '../../img/Rentals/Lights/CUSTOMIZED GATE.jpg'
import rentals11 from '../../img/Rentals/Lights/GATE (CUSTOMIZED).jpg'
import rentals12 from '../../img/Rentals/Lights/GATE WITH DECORATION.jpg'
import rentals13 from '../../img/Rentals/Lights/LED SCREEN.jpg'
import rentals14 from '../../img/Rentals/Lights/NOTICE BOARD STAND.jpg'


import styles from './Hangers.module.css'

const Stage = () => {
    const [model, setModel] = useState(false);
    const [tempSrc, setTempSrc] = useState(null);

    const getImg = useCallback((img) => {
        setTempSrc(img);
        setModel(true);
    }, []);
    const handleModelClose = () => {
        setModel(false)
    }
    return (
        <>
            <div className={model ? `${styles.model} ${styles.open}` : styles.model}>
                <div className={styles.modelimage} >
                    {tempSrc && (
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                            <i className="fa-solid fa-xmark fa-2xl" style={{ color: "#ffffff" }} onClick={handleModelClose}></i>
                            <img src={tempSrc} alt="large" style={{ width: '65%' }} />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.hangers}>



                <div className={styles.Card}>
                    <div className={styles.imagesection} onClick={() => getImg(rentals1)}>
                        <img src={rentals1} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Big
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>CONCERT STAGE</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals2)}>
                        <img src={rentals2} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Small
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>STAGE WITH CHAIRS</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals3)}>
                        <img src={rentals3} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Decoration
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>STAGE WITH DECORATION</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals4)}>
                        <img src={rentals4} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            LED Screen
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>STAGE WITH LED SCREEN</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals5)}>
                        <img src={rentals5} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Big
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>BIG STAGE</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals6)}>
                        <img src={rentals6} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Medium
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>TRUSS STAGE</div>

                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals8)}>
                        <img src={rentals8} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Decoration
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>OCTAGON GATE</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals9)}>
                        <img src={rentals9} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Decoration
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>ARC WITH LIGHTS</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals10)}>
                        <img src={rentals10} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Custom
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>CUSTOMIZED GATE</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals11)}>
                        <img src={rentals11} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Custom
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>GATE CUSTOM</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals12)}>
                        <img src={rentals12} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Decoration
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>GATE WITH DECORATION</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals13)}>
                        <img src={rentals13} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            LED Screen
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>LED SCREEN</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals14)}>
                        <img src={rentals14} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Decoration
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>NOTICE BOARD STAND</div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Stage
