import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import "./Gallery.css";
import PastEvents from "../PastEvent.json";
import { NameContext } from "../context/NameContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import CloseIcon from '@mui/icons-material/Close';

const Gallery = () => {
  // const { name } = useParams();
  const [tag, setTag] = useState("all");
  const [tempSrc, setTempSrc] = useState(null);
  const [model, setModel] = useState(false);

  const images = useMemo(() => PastEvents, []);

  const filterImages = useMemo(() => {
    return tag === "all"
      ? images
      : images.filter((image) => image.name === tag);
  }, [tag, images]);

  const yearBasedArrays = useMemo(() => {
    const arraysByYear = {};
    filterImages.forEach((image) => {
      const year = image.year;
      if (arraysByYear.hasOwnProperty(year)) {
        arraysByYear[year].push(image);
      } else {
        arraysByYear[year] = [image];
      }
    });
    return arraysByYear;
  }, [filterImages]);

  const getImg = useCallback((img) => {
    setTempSrc(img);
    setModel(true);
  }, []);
  const handleModelClose = () => {
    setModel(false);
  };
  const { name } = useContext(NameContext);
  useEffect(() => {
    setTag(name); // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="gallery-outer-section" style={{ marginTop: "80px" }}>
        <div className={model ? "model open" : "model"}>
          <div className="modelimage">
            {tempSrc && (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="fa-solid fa-xmark fa-2xl"
                  style={{ color: "#ffffff" }}
                  onClick={handleModelClose}
                ></i>
                <LazyLoadImage
                  src={`/images/PastEvents/${tempSrc.name}/${tempSrc.year}/${tempSrc.imagename}`}
                  alt="large"
                  style={{ width: "80%" }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="tags">
          {/* <TagButton name="all" handleSetTag={setTag} tagActive={tag === 'all'} /> */}
          {/* <TagButton name="Global" handleSetTag={setTag} tagActive={tag === 'Global'} /> */}
          <TagButton
            name="Furnex"
            handleSetTag={setTag}
            tagActive={tag === "Furnex"}
          />
          <TagButton
            name="Himalayan Travel Mart"
            handleSetTag={setTag}
            tagActive={tag === "Himalayan Travel Mart"}
          />
          <TagButton
            name="KANTIPUR HISSAN EDUFAIR"
            handleSetTag={setTag}
            tagActive={tag === "KANTIPUR HISSAN EDUFAIR"}
          />
          <TagButton
            name="Himalayan Hydro Expo"
            handleSetTag={setTag}
            tagActive={tag === "Himalayan Hydro Expo"}
          />
        </div>
        <div className="container ">
          {Object.entries(yearBasedArrays).map(([year, yearArray]) => (
            <>
              <h1
                style={{
                  color: "white",
                  textAlign: "left",
                  marginTop: "20px",
                  fontSize: "25px",
                  marginBottom: "13px",
                }}
              >
                {year}
              </h1>
              <div key={year} className="gallery">
                {yearArray.map((image) => (
                  <div
                    key={image.id}
                    className="image-card"
                    onClick={() => getImg(image)}
                  >
                    <img
                      className="image"
                      src={`/images/PastEvents/${image.name}/${image.year}/${image.imagename}`}
                      alt={image.name}
                    />
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

const TagButton = ({ name, handleSetTag, tagActive }) => {
  return (
    <button
      className={`tag ${tagActive ? "actively" : "null"}`}
      onClick={() => handleSetTag(name)}
    >
      {name.toUpperCase()}
    </button>
  );
};

export default Gallery;
