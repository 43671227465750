import React from 'react'
import styles from './WorkFlow.module.css'


const WorkFlow = () => {
    return (
        <div className={styles.workflowbody}>
            <h2>Our Approach</h2>
            <div className={styles.skewed}>
                <div className={styles.text}>
                    <span className="material-symbols-outlined" style={{ fontSize: '150px' }}>
                        menu_book
                    </span>
                </div>

                <div className={styles.image}>
                    <div className={styles.learnText}>
                        <h5>LEARN & PLAN</h5>
                        <p className={styles.Workp}>We learn about your business, challenges, demand and your requirement the way you want your event to be like. We study and analyze all twists and turns and go forward for the best, so the first step for us is about what you actually want your event to be.</p>
                    </div>
                </div>
            </div>
            <div className={`${styles.skewed} ${styles.skewedLeft}`}>
                <div className={styles.image}>
                    <div className={styles.buildtext}>
                        <h5>BUILD & TARGET</h5>
                        <p className={styles.Workp}>Let us look after the details and the heavy lifting that comes with planning a professional event. From our network of preferred vendors, we can deliver a full service.</p>
                    </div>
                </div>
                <div className={styles.text}>
                    {/* <h6>hello</h6> */}
                    <span className="material-symbols-outlined" style={{ fontSize: '150px' }}>
                        tornado
                    </span>

                </div>
            </div>
            <div className={styles.skewed}>
                <div className={styles.text}>
                    <span className="material-symbols-outlined" style={{ fontSize: '150px' }}>
                        ads_click
                    </span>
                </div>

                <div className={styles.image}>
                    <div className={styles.learnText}>
                        <h5>EXECUTION & DELIVER</h5>

                        <p className={styles.Workp}>Finally, this is where our event management expertise comes into execution.From meticulous management of facility details ato AV, catering and onsite coordination, we ensure every detail is looked after.</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default WorkFlow
