import React from "react";
import bg from "../../img/background4.jpg";
import servicebg from "../../img/serviceBG.jpg";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div className="services" style={{ padding: "50px 0" }}>
      <div className="backdrop">
        <img src={bg} alt="bg" />
      </div>
      <div className="container my-10">
        <h1 style={{ color: "white" }}>Our Services</h1>
        <div className="services-grid">
          <div className="">
            <Link to="/eventManagement" style={{ textDecoration: "none" }}>
              <div className="card my-1">
                <div className="services-backdrop">
                  <img src={servicebg} alt="bg" />
                </div>
                <div className="card-body my-3">
                  <div className="circle my-4">
                    <Link to="/eventManagement">
                      <i
                        className="fa-solid fa-handshake fa-2xl"
                        style={{ color: "#065b9c" }}
                      ></i>
                    </Link>
                  </div>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    Event Management
                  </h6>
                  <p className="card-text">
                    We manage all kinds of event formal to informal, exhibition
                    to concert, wedding and more....
                  </p>
                </div>
              </div>
            </Link>
          </div>

          <div className="">
            <Link to="/rentals" style={{ textDecoration: "none" }}>
              <div className="card my-1">
                <div className="services-backdrop">
                  <img src={servicebg} alt="bg" />
                </div>
                <div className="card-body my-3">
                  <div className="circle my-4">
                    <Link to="/rentals">
                      <i
                        className="fa-solid fa-house fa-2xl"
                        style={{ color: "#065b9c" }}
                      ></i>
                    </Link>
                  </div>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    HANGERS
                  </h6>
                  <p className="card-text">
                    Diffrent sizes hangers available to make every ocassion
                    special.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="">
            <Link to="/eventManagement" style={{ textDecoration: "none" }}>
              <div className="card my-1">
                <div className="services-backdrop">
                  <img src={servicebg} alt="bg" />
                </div>
                <div className="card-body my-3">
                  <div className="circle my-4">
                    {" "}
                    <Link to="/rentals">
                      <i
                        className="fa-solid fa-chart-simple fa-2xl"
                        style={{ color: "#065b9c" }}
                      ></i>
                    </Link>
                  </div>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    LOGISTICS
                  </h6>
                  <p className="card-text">
                    We provide almost all logistics required for your events
                    i.e. stalls , tables, chairs, pandal, tent, etc.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="">
            <Link to="/rentals" style={{ textDecoration: "none" }}>
              <div className="card my-1">
                <div className="services-backdrop">
                  <img src={servicebg} alt="bg" />
                </div>
                <div className="card-body my-3">
                  <div className="circle my-4">
                    <Link to="/rentals">
                      <i
                        className="fa-solid fa-video fa-2xl"
                        style={{ color: "#065b9c" }}
                      ></i>
                    </Link>
                  </div>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    PHOTOGRAPHY/VIDEOGRAPHY
                  </h6>
                  <p className="card-text">
                    We capture your beautiful moments and make those memories
                    last forever.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="">
            <Link to="/rentals" style={{ textDecoration: "none" }}>
              <div className="card my-1">
                <div className="services-backdrop">
                  <img src={servicebg} alt="bg" />
                </div>
                <div className="card-body my-3">
                  <div className="circle my-4">
                    <Link to="/rentals">
                      <i
                        className="fa-solid fa-shield-halved fa-2xl"
                        style={{ color: "#065b9c" }}
                      ></i>
                    </Link>
                  </div>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    SOUND SYSTEM AND SECURITY
                  </h6>
                  <p className="card-text">
                    We give technologically viable best sound systems. Also,
                    take care of your events and help provide security to get
                    rid of possible harm.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="">
            <Link to="/eventManagement" style={{ textDecoration: "none" }}>
              <div className="card my-1">
                <div className="services-backdrop">
                  <img src={servicebg} alt="bg" />
                </div>
                <div className="card-body my-3">
                  <div className="circle my-4">
                    {" "}
                    <Link to="/eventManagement">
                      <i
                        className="fa-solid fa-bars fa-2xl"
                        style={{ color: "#065b9c" }}
                      ></i>
                    </Link>
                  </div>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    DIGITAL MARKETING AND DESIGNING
                  </h6>
                  <p className="card-text">
                    Social media marketing , designing and presenting it in
                    possible best way.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
