import React from 'react'
import styles from './DetailsSection.module.css'
// import middleImage from '../../img/AboutPage2.jpg'
// import middleImageJoin from '../../img/Group3.png'
import images from '../../img/GroupPhoto15.png'


const DetailsSection = () => {
    return (
        <div className={styles.outerDetails}>
            <div className={styles.textSection}>
                <h2>About Company</h2>
                <p><span>Event Solution Pvt. Ltd.</span>, founded in 2014, is a constructive and highly dedicated organization founded with the goal of managing and arranging various events in various fields. The name says it all: Event Solution is a firm that aims to deliver the best solutions for all of your events, making event planning a stress-free.</p><p>
                    We have hundreds of clients ranging from major corporations to global brands, and we have successfully designed, coordinated, and executed large-scale events. Our caring team of experts helps your vision become a reality and adds fascinating insights to your thoughts; furthermore we also provide rental services, creative desiging servies, event counseling and all other necessary required services in for a successful event.</p><p> Our team believes in working as a team to achieve the greatest outcomes, and their professional expertise working in this industry for many years has set our own benchmark with the best client ratings. We have established a benchmark in the exhibition planning, helping the corporate sector to reach new heights.</p>
            </div>
            <div className={styles.imageSection}>
                {/* <div className={styles.rectangleUp}></div>
                <div className={styles.rectangleDown}></div>
                */}
                {/* <img src={middleImageJoin} alt="middle"></img> */}
                <img src={images} alt="middle"></img>
            </div>
        </div>
    )
}

export default DetailsSection
