import React, { useState, useCallback } from 'react'
import rentals1 from '../../img/Rentals/Hangers/HANGER (10M X 18M).jpg'
import rentals2 from '../../img/Rentals/Hangers/HANGER STRUCTURE.jpg'
import rentals3 from '../../img/Rentals/Hangers/HANGER TRANSPARENT 20M X 25M.jpg'
import rentals4 from '../../img/Rentals/Hangers/HANGER WITH LIGHTS & DECOR.jpg'
import rentals5 from '../../img/Rentals/Hangers/HANGER WITH LIGHTS.jpg'
import rentals6 from '../../img/Rentals/Hangers/HUGE HANGER.jpg'
// import rentals7 from '../../img/Rentals/Hangers/INSIDE HANGER.jpg'
import rentals8 from '../../img/Rentals/Hangers/INSIDES HANGER.jpg'
import rentals9 from '../../img/Rentals/Hangers/MEDIUM & SMALL HANGER.jpg'
import rentals10 from '../../img/Rentals/Hangers/TRANSPARENT HANGER 12 X 40M.jpg'
import rentals11 from '../../img/Rentals/Hangers/TRANSPARENT HANGER DECORATION.jpg'
import rentals12 from '../../img/Rentals/Hangers/TRANSPARENT HANGER.jpg'
import rentals13 from '../../img/Rentals/Hangers/VENUE DECORATION.jpg'
import styles from './Hangers.module.css'

const Hangers = () => {
    const [model, setModel] = useState(false);
    const [tempSrc, setTempSrc] = useState(null);

    const getImg = useCallback((img) => {
        setTempSrc(img);
        setModel(true);
    }, []);
    const handleModelClose = () => {
        setModel(false)
    }
    return (
        <>
            <div className={model ? `${styles.model} ${styles.open}` : styles.model}>
                <div className={styles.modelimage} >
                    {tempSrc && (
                        <div style={{ margin: '0px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                            <i className="fa-solid fa-xmark fa-2xl" style={{ color: "#ffffff" }} onClick={handleModelClose}></i>
                            <img src={tempSrc} alt="large" style={{ width: '60%' }} />
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.hangers}>

                <div className={styles.Card}>
                    <div className={styles.imagesection} onClick={() => getImg(rentals1)}>
                        <img src={rentals1} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Small
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>Size : 10M X 18M</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals2)}>
                        <img src={rentals2} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0  translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Big
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>HANGER STRUCTURE</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals3)}>
                        <img src={rentals3} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0  translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Transparent
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>Size : 20M X 25M</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals4)}>
                        <img src={rentals4} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0  translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Decoration
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>HANGER WITH LIGHTS & DECOR</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals5)}>
                        <img src={rentals5} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Decoration
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>HANGER WITH LIGHTS</div>
                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals6)}>
                        <img src={rentals6} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Big
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>HUGE HANGER</div>

                    </div>
                    {/* <div className={styles.imagesection} onClick={() => getImg(rentals7)}>
                    <img src={rentals7} alt="rentals1"></img>
                    <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                        Interior
                        <span className="visually-hidden">unread messages</span>
                    </span>
                    <div className={styles.overlay}>INSIDE HANGER</div>
                </div> */}
                    <div className={styles.imagesection} onClick={() => getImg(rentals8)}>
                        <img src={rentals8} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Interior
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>INSIDES HANGER</div>

                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals9)}>
                        <img src={rentals9} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Medium & Small
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>MEDIUM & SMALL HANGER</div>

                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals10)}>
                        <img src={rentals10} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Transparent
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>TRANSPARENT HANGER 12 X 40M</div>

                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals11)}>
                        <img src={rentals11} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Transparent
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>TRANSPARENT HANGER DECORATION</div>

                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals12)}>
                        <img src={rentals12} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Transparent
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>TRANSPARENT HANGER</div>

                    </div>
                    <div className={styles.imagesection} onClick={() => getImg(rentals13)}>
                        <img src={rentals13} alt="rentals1"></img>
                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger" style={{ zIndex: 4 }}>
                            Decoration
                            <span className="visually-hidden">unread messages</span>
                        </span>
                        <div className={styles.overlay}>VENUE DECORATION</div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Hangers
